export default defineNuxtPlugin((nuxtApp) => {
  const numberToHuman = (num: number): string => {
    if (num == 0) {
      return 0
    } else if (num < 1000) {
      return num.toString()
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + "K"
    } else if (num < 1000000000) {
      return (num / 1000000).toFixed(1) + "M"
    } else {
      return (num / 1000000000).toFixed(1) + "B"
    }
  }

  nuxtApp.provide("numberToHuman", numberToHuman)
})
