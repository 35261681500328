import posthog from "posthog-js"
import { storeToRefs } from "pinia"

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const userStore = useUserStore()
  const { loggedIn, username } = storeToRefs(userStore) // Ensure reactive refs

  let posthogClient

  if (import.meta.env.MODE !== "development") {
    posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
      api_host: runtimeConfig.public.posthogHost || "https://us.i.posthog.com",
      person_profiles: "identified_only",
      capture_pageview: false, // We handle manually
    })

    // Identify the user if initially logged in
    if (loggedIn.value && username.value) {
      posthogClient.identify(username.value)
    }

    // Watch for changes in login state and username
    watch(
      [loggedIn, username], // Watch multiple reactive refs
      ([newLoggedIn, newUsername]) => {
        if (newLoggedIn && newUsername) {
          posthogClient.identify(newUsername)
        } else {
          posthogClient.reset()
        }
      },
      { immediate: true }, // Trigger immediately to catch initial state
    )

    // Capture pageviews on route changes
    const router = useRouter()
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture("$pageview", {
          current_url: to.fullPath,
        })
      })
    })
  } else {
    posthogClient = {
      capture: () => {},
      identify: () => {},
      reset: () => {},
    }
  }

  // Provide PostHog client to the app
  nuxtApp.provide("posthog", posthogClient)
})
