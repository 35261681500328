import { defineNuxtPlugin } from "#app"
import { useUserStore } from "@/store/user"

export default defineNuxtPlugin(() => {
  const { $bus } = useNuxtApp()
  const toast = useToast()
  const userStore = useUserStore()
  const route = useRoute()

  $bus.$on("new-direct-message", ({ payload }) => {
    if (payload.sender.username === userStore.username) return

    if (route.path != '/messages') {
      toast.add({
        title: `New Message from ${payload.sender.username}`,
        avatar: { src: payload.sender.signedProfilePhotoThumbUrl },
        description: payload.text,
        status: "info",
        duration: 9000,
        isClosable: true,
      })
    }
  })
})
