import { useUserStore } from "@/store/user"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:mounted", () => {
    // This is used so when you do a browser refresh, we get updated user data.
    // Sometimes this is cached somehow and requires more than 1 browser refresh to fire....
    const userStore = useUserStore()
    userStore.getData()
  })
})
