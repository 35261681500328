import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import {
  fpjsPlugin,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-vue-v3";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(fpjsPlugin, {
    loadOptions: {
      apiKey: config.public.FINGERPRINT_API_KEY,
      endpoint: [
        "https://favorite.ly/y5iysls20kueQaEE/3OONoMJwg3LyXjsm",
        FingerprintJSPro.defaultEndpoint
      ].filter(x => x),
      scriptUrlPattern: [
        "https://favorite.ly/y5iysls20kueQaEE/oUSo6EhBT4k8iyWJ?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
        FingerprintJSPro.defaultScriptUrlPattern
      ].filter(x => x),
    },
  });
});
