import { useUserStore } from "@/store/user"

export function useDirectMessageCounts() {
  const config = useRuntimeConfig()
  const userStore = useUserStore()

  const readCount = ref(0)
  const totalCount = ref(0)
  const unreadCount = ref(0)

  const fetchCounts = async function () {
    if (userStore.loggedIn) {
      try {
        const response = await $api(
          `${config.public.API_URL}/api/conversations/unread_count`,
        )
        readCount.value = response.read
        totalCount.value = response.total
        unreadCount.value = response.unread
      } catch (e) {
        console.error(e)
      }
    }
  }

  return {
    fetchCounts,
    readCount,
    totalCount,
    unreadCount,
  }
}
