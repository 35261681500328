import { useUserStore } from "@/store/user"

export function $api(request, opts) {
  const userStore = useUserStore()
  const headers = { ...opts?.headers }

  if (userStore.loggedIn) {
    headers.Authorization = `Bearer ${userStore.token}`
  }

  // Access errors from a try/catch with `error.data`
  return $fetch(request, { ...opts, headers: headers })
}
