export default defineAppConfig({
  ui: {
    primary: "pink",
    cool: "cool",
    modal: {
      width: "sm:min-w-[300px]",
    },
    notification: {
      title: "text-base",
    },
    notifications: {
      position: "top-0 bottom-auto",
    },
    popover: {
      overlay: {
        base: "fixed inset-0 transition-opacity z-50",
        background: "bg-gray-100/90 dark:bg-black/90",
      },
      rounded: "rounded-xl",
    },
    radio: {
      inner: "w-full flex flex-col",
      label: "w-full text-sm font-normal text-gray-700 dark:text-gray-200",
      wrapper:
        "relative w-full flex flex-row items-center px-3 py-1.5 rounded-md hover:bg-cool-100 hover:dark:bg-[#2B3236]",
    },
  },
})
