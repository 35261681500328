import { useUserStore } from "@/store/user"
import ActionCableVue from "actioncable-vue"

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const config = useRuntimeConfig()
    const userStore = useUserStore()

    const options = {
      debug: process.env.NODE_ENV == "development",
      debugLevel: process.env.NODE_ENV == "development" ? "all" : "error",
      connectionUrl: () => {
        return `${config.public.WS_URL}?token=${userStore.token}`
      },
      connectImmediately: false,
    }

    nuxtApp.vueApp.use(ActionCableVue, options)
  }
})
