export default defineNuxtPlugin((nuxtApp) => {
  const provinces = [
    {
      name: "Quebec",
      abbreviation: "QC",
    },
    {
      name: "Nova Scotia",
      abbreviation: "NS",
    },
    {
      name: "New Brunswick",
      abbreviation: "NB",
    },
    {
      name: "Manitoba",
      abbreviation: "MB",
    },
    {
      name: "British Columbia",
      abbreviation: "BC",
    },
    {
      name: "Prince Edward Island",
      abbreviation: "PE",
    },
    {
      name: "Saskatchewan",
      abbreviation: "SK",
    },
    {
      name: "Alberta",
      abbreviation: "AB",
    },
    {
      name: "Newfoundland and Labrador",
      abbreviation: "NL",
    },
    {
      name: "Northwest Territories",
      abbreviation: "NT",
    },
    {
      name: "Yukon",
      abbreviation: "YT",
    },
    {
      name: "Nunavut",
      abbreviation: "NU",
    },
  ]

  nuxtApp.provide("provinces", provinces)
})
