export default defineNuxtRouteMiddleware((to) => {
  const runtimeConfig = useRuntimeConfig()
  const isMaintenance = runtimeConfig.public.MAINTENANCE.trim().toLowerCase()

  if (isMaintenance === "true" && to.fullPath !== "/maintenance") {
    return navigateTo("/maintenance")
  }

  if (isMaintenance === "false" && to.fullPath == "/maintenance") {
    return navigateTo("/")
  }
})
