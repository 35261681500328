
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'


import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: ["en",{"relativeTime":{"future":"in %s","past":"%s ago","s":"a few seconds","m":"a minute","mm":"%d min","h":"an hour","hh":"%d hours","d":"a day","dd":"%d days","M":"a month","MM":"%d months","y":"a year","yy":"%d years"}}]


dayjs.updateLocale("en",{"relativeTime":{"future":"in %s","past":"%s ago","s":"a few seconds","m":"a minute","mm":"%d min","h":"an hour","hh":"%d hours","d":"a day","dd":"%d days","M":"a month","MM":"%d months","y":"a year","yy":"%d years"}})
dayjs.locale('en')


export default dayjs
